<template>
	<div>
		<dashboard-mobile v-if="$isMobile()" />
		<dashboard-desktop v-else />		
	</div>
</template>
<script>
	import DashboardMobile from './DashboardMobile';
	import DashboardDesktop from './DashboardDesktop';
	import {mapGetters} from 'vuex';
	
	export default {
		components: {
			DashboardMobile,
			DashboardDesktop,
		},
		computed: {
			...mapGetters('App', ['configs']),
		},
		methods: {
			isMobile() {
			if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
					return true
				} else {
					return false
				}
			}
		},
		mounted(){
			if(this.configs && Object.keys(this.configs).length > 0 && !this.configs.has_books && !this.isMobile()){
				this.$router.push({name: 'orders'});
			}
		}
	}
</script>