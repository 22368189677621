<template>
	<main class="dashboard">
		<section class="content-dashboard">
			<div class="content-dashboard-bg">
				<img class="img-fluid" src="@/assets/img/dashboard/logo-eurest-blue.png" alt="logo eurest" title="logo eurest"/>
				<div class="content-menu-mobile">
					<a class="content-menu-item btn-menu" @click="openMenuNav(!menuNavOpen)">
		        <span></span>
		        <p>Menu</p>
		      </a>
				</div>
				<div class="content-dashboard-menu">
					<div v-if="configs.has_recharge_balance" class="content-dashboard-menu-header">
						<h2>O seu Saldo</h2>
						<h3>{{ user.balance|money }}</h3>
					</div>
					<div class="content-dashboard-menu-links">
						<router-link v-if="configs.has_books" :to="{name:'books'}">
							<img class="img-fluid" src="@/assets/img/dashboard/marcacoes.png" alt="image marcacoes" title="image reservas"/>
							Reservas
						</router-link>
						<router-link v-if="configs.has_orders" :to="{name:'orders'}">
							<img class="img-fluid" src="@/assets/img/dashboard/pedidos.png" alt="image pedidos" title="image reservas"/>
							Pedidos
						</router-link>
						<!-- <a v-if="configs.has_orders" data-toggle="modal" href="#modal-requests">
							<img class="img-fluid" src="@/assets/img/dashboard/pedidos.png" alt="image pedidos" title="image pedidos"/>
							Pedidos
						</a> -->
					</div>
				</div>
			</div>
		</section>
	</main>
</template>
<script>
	import {mapGetters, mapActions} from 'vuex';

	export default {
		computed: {
			...mapGetters('App', ['configs', 'menuNavOpen']),
			...mapGetters('User', ['user']),
		},
		methods: {
			...mapActions('App', ['openMenuNav']),
		}
	}
</script>